import { Directive, HostListener, output } from '@angular/core';

@Directive({
  selector: '[ammScrollDirection]',
  standalone: true,
})
export class ScrollDirectionDirective {
  public scrollDirection = output<string>();

  private lastScrollPosition: number = 0;

  @HostListener('document:scrollend', ['$event'])
  private onScroll(event: Event) {
    if (this.lastScrollPosition < window.scrollY) {
      this.scrollDirection.emit('Down');
      this.lastScrollPosition = window.scrollY;
    } else {
      this.scrollDirection.emit('Up');
      this.lastScrollPosition = window.scrollY;
    }
  }
}
