import {
  Directive,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { debounceTime, Observable, Subscription } from 'rxjs';

@Directive({
  selector: '[ammIntersectionObserver]',
  standalone: true,
  exportAs: 'intersection',
})
export class IntersectionObserverDirective implements OnInit, OnDestroy {
  @Input() root: HTMLElement | null = null;
  @Input() rootMargin = '0px 0px 0px 0px';
  @Input() threshold = 0;
  @Input() debounceTime = 250;
  @Input() isContinuous = false;

  @Output() onIntersecting = new EventEmitter<boolean>();

  private _isIntersecting: boolean = false;
  private element: ElementRef = inject(ElementRef);
  private subscription: Subscription | undefined;
  ngOnInit(): void {
    this.subscription = this.createAndObserve();
  }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private createAndObserve() {
    const options: IntersectionObserverInit = {
      root: this.root,
      rootMargin: this.rootMargin,
      threshold: this.threshold,
    };

    return new Observable<boolean>((subscriber) => {
      const intersectionObserver: IntersectionObserver =
        new IntersectionObserver((entries) => {
          const { isIntersecting } = entries[0];
          subscriber.next(isIntersecting);
          isIntersecting &&
            !this.isContinuous &&
            intersectionObserver.disconnect();
        }, options);

      intersectionObserver.observe(this.element.nativeElement);
      return {
        unsubscribe() {
          intersectionObserver.disconnect();
        },
      };
    })
      .pipe(debounceTime(this.debounceTime))
      .subscribe((isIntersecting: boolean) => {
        this._isIntersecting = isIntersecting;
        this.onIntersecting.emit(isIntersecting);
      });
  }
}
